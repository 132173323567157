import React from 'react';
import FooterComponent from '../component/footer.jsx';

export default function Footer({ user, setUser, state }) {
    return (
        <FooterComponent
            onWebmastersClick={event => showPage(event, `/webmasters`)}
            onUsc2257Click={event => showPage(event, `/usc2257`)}
            onPrivacyClick={event => showPage(event, `/privacy`)}
            onTermsClick={event => showPage(event, `/terms`)}
            contactEmail={state.root.email.$contact}
            onLogoutClick={doLogout}
            isLoggedIn={user}
        />
    );

    function showPage(event, page) {
        event.preventDefault();
        state.page.navigate(page);
    }

    async function doLogout(event) {
        event.preventDefault();
        await fetch(`${state.root.key.api}/api/legacy/user/logout`, {
            method: `get`,
            credentials: `include`,
        });
        setUser(undefined);
    }
}
