import assert from 'assert';

// Pages
import createHomeState from './home.js';
import createModelsState from './models.js';
import createModelState from './model.js';
import createVideosState from './videos.js';
import createVideoState from './video.js';
import createWelcomeState from './welcome.js';
// import createLinkedLoginState from './linked-login.js';
import createState from '../state.js';

const VERSION_ROUTE = /^\/v\d+_\d+_\d+.*(\/.*)$/;
const FALSE_BASE_URL = `http://cd4083406e10.com`;

export default function createPageState({ root, values: { current } = {} }) {
    // TODO: Pass params... how to extract? They are different per page
    current = pageStateFromPageData(root, current);

    const state = createState({
        root,
        values: {
            current,
        },
        derived: {
            title: {
                from: [`current.title`],
                combine: buildTitle,
            },
            path: {
                from: [`current`],
                combine: buildPath,
            },
        },
        createActions,
    });

    return state;

    function createActions(state) {
        return {
            navigate,
            notFound,
        };

        function notFound() {
            state.current = createState({
                root,
                values: {
                    $page: `404`,
                    title: `Not Found`,
                },
            });
        }

        async function navigate(path) {
            path = normalizePath(path);
            assert(path.startsWith(`/`), `path MUST start with "/". Got "${path}"`);

            if (!pathUnderstood(path)) {
                notFound();
                return;
            }

            // Make we apply the same canonical value every time to prevent
            //  unecessary changes
            path = canonicalizePath(path);

            // Don't create a new state if not necessary
            if (path !== state.path) {
                // Update the current state
                state.current = pageStateFromPath(root, path);
            }

            // This should only load if we don't already have data
            if (typeof state.current.load === `function`) {
                await state.current.load();
            }
        }
    }

    function buildTitle(state) {
        return [`${state.root.$site}.com`, state.current?.title || state.root.$catchPhrase]
            .filter(Boolean)
            .join(` - `);
    }

    function buildPath(state) {
        switch (state.current.$page) {
            case `home`:
                return `/`;
            case `models`:
                // TODO: Params should be specific
                return `/models${params(state.current.params)}`;
            case `videos`:
                // TODO: Params should be specific
                return `/videos${params(state.current.params)}`;
            case `model`:
                return `/models/${encodeURIComponent(state.current.model.modelId)}`;
            case `video`:
                if (state.current.photoIndex > -1) {
                    return (
                        `/videos/${encodeURIComponent(state.current.video.videoId)}` +
                        `?photo=${encodeURIComponent(state.current.photoIndex)}`
                    );
                } else {
                    return `/videos/${encodeURIComponent(state.current.video.videoId)}`;
                }
            case `404`:
                // Return the current path
                return state.path;
            default:
                return `/${state.current.$page}`;
        }

        function params(values) {
            if (!values) {
                return ``;
            }
            const entries = Object.fromEntries(values);
            if (entries.length === 0) {
                return ``;
            }

            values = entries
                .map(([name, value]) => `${encodeURIComponent(name)}=${encodeURIComponent(value)}`)
                .join(`&`);

            return `?${values}`;
        }
    }

    function pageStateFromPath(root, path) {
        const url = new URL(path, FALSE_BASE_URL);
        const params = Object.fromEntries(url.searchParams.entries());
        let [, page, ...subPage] = url.pathname.split(`/`);
        if (page === ``) {
            page = `home`;
        }

        const data = { $page: page };
        // TODO: More generic way to handle this?
        if (page === `videos` && subPage[0]) {
            data.$page = `video`;
            data.videoId = decodeURIComponent(subPage[0]);
        } else if (page === `models` && subPage[0]) {
            data.$page = `model`;
            data.modelId = decodeURIComponent(subPage[0]);
        } else if (page === `join` && subPage.length) {
            data.biller = subPage.join(`/`);
        }

        return pageStateFromPageData(root, data, params);
    }

    function pageStateFromPageData(root, { $page, ...data } = {}, params) {
        switch ($page) {
            case undefined:
            case `home`:
                return createHomeState({ root, values: data });
            case `model`:
                return createModelState({ root, values: data });
            case `models`:
                // TODO: Add relevant params as values
                return createModelsState({ root, values: data });
            case `video`:
                return createVideoState({ root, values: data });
            case `videos`:
                return createVideosState({ root, values: data });
            case `welcome`:
                return createWelcomeState({ root, values: data });
            // case `linked-login`:
            //     return createLinkedLoginState({ root, values: data });
            default:
                return createState({
                    root,
                    values: {
                        $page,
                        title: $page,
                    },
                });
        }
    }

    function normalizePath(path) {
        if (path.endsWith(`/`)) {
            path = path.substr(0, path.length - 1);
        }

        if (!path) {
            path = `/`;
        }

        if (VERSION_ROUTE.test(path)) {
            path = path.replace(VERSION_ROUTE, `$1`);
        }

        return path;
    }

    function canonicalizePath(path) {
        // TODO: We need to expose this for usage externally (to check is window should push satte...
        //  might be good externally with default values applied?)
        const url = new URL(path, FALSE_BASE_URL);
        let qs = ``;
        const entries = Array.from(url.searchParams.entries());
        if (entries.length) {
            // TODO: Default values?
            const params = entries
                .sort(([name1], [name2]) => {
                    if (name1 < name2) {
                        return -1;
                    } else if (name1 > name2) {
                        return 1;
                    } else {
                        return 0;
                    }
                })
                .map(([key, value]) => {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                })
                .join(`&`);
            qs = `?${params}`;
        }

        return `${url.pathname}${qs}`;
    }

    function pathUnderstood(path) {
        const url = new URL(path, FALSE_BASE_URL);
        const [, page, ...subPage] = url.pathname.split(`/`);
        switch (page) {
            case ``:
            case `home`:
            case `webmasters`:
            case `usc2257`:
            case `privacy`:
            case `terms`:
            case `welcome`:
            case `linked-login`:
            case `password-reset`:
            case `search-results`:
                return subPage.length === 0;
            case `models`:
            case `videos`:
                return subPage.length <= 1;
            default:
                return false;
        }
    }

    function ignoreFirst(handler) {
        let first = true;
        return function firstIgnored(...args) {
            if (first) {
                first = false;
                return undefined;
            } else {
                return handler(...args);
            }
        };
    }
}
