import React from 'react';
import styled from 'styled-components';
import constant from '../constants.js';
import SlideInfo from '../component/slide/info.jsx';
import CardImageTitled from '../component/card/image-titled.jsx';
import Navigate from '../container/navigate.jsx';
import Cards from '../container/cards.jsx';
import { Discounts } from '../container/discounts.jsx';
import { Banner } from '../component/banner.jsx';

const StyledPage = styled.div`
    display: flex;
    flex-direction: column;
`;

const BannerContainer = styled.div`
    margin-bottom: 1em;
`;

const Title = styled.h2`
    text-transform: uppercase;
    font-size: 1.25em;
    margin: 0.25em;
    margin-top: 1em;
`;

const PlayArrow = styled.div`
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 8px solid var(--color-action-text);
    display: inline-block;
    margin-right: 0.25em;
`;

const WatchNowButton = styled.div`
    color: var(--color-action-text);
    text-transform: uppercase;
    @media all and (max-width: ${constant.size.medium}) {
        font-size: 0.9em;
        h2 > span {
            position: relative;
            padding: 0.5em;
            background-color: rgba(var(--color-action-active-rgb), 0.8);
        }
    }
`;

const linkStyle = { width: `100%` };

const OurStory = styled.div`
    margin-left: 1em;
    margin-right: 1em;

    h1 {
        text-align: center;
    }
`;

const News = styled.div`
    display: flex;
    margin-left: 1em;
    margin-right: 1em;

    @media (max-width: ${constant.size.mobile}) {
        flex-direction: column;
    }
`;

const Column = styled.div`
    flex-basis: ${({ size }) => size || `33.3%`};

    @media (max-width: ${constant.size.mobile}) {
        flex-basis: inherit;
    }
`;

const TextContent = styled.div`
    margin: 0.25em;
    margin-top: 1.5em;
    font-family: var(--font-tertiary);
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ViewAll = styled(Navigate)`
    margin-bottom: 0.4em;
    margin-right: 0.2em;
    align-self: flex-end;
    font-size: 0.875em;
    padding: 0.3em;
    padding-left: 1em;
    padding-right: 1em;
    font-weight: 600;
`;

export default function PageHome({ state, user }) {
    const latest = state.useLatest();
    const recentModels = state.useRecentModels();
    const features = state.useFeatures();
    const next = state.useNext();
    const news = state.useNews();
    const ourStory = state.useOurStory();
    const special = state.useSpecial();
    const specialLink = state.useSpecialLink();

    return (
        <>
            {!user && (
                <BannerContainer>
                    <Banner state={state} special={special} specialLink={specialLink} />
                </BannerContainer>
            )}
            <StyledPage className="page">
                <Navigate
                    state={state.root}
                    style={linkStyle}
                    href={`/videos/${latest.videoId}`}
                    disabled={latest.loading}
                >
                    <SlideInfo
                        size={constant.size}
                        title={latest.models[0].name}
                        info={latest.description.home}
                        type="Latest Update"
                        source={latest.homeImage}
                        Action={() => (
                            <WatchNowButton className="watch-now primary-text">
                                <div className="background primary"></div>
                                <h2>
                                    <span>
                                        <PlayArrow />
                                        Watch Now
                                    </span>
                                </h2>
                            </WatchNowButton>
                        )}
                    />
                </Navigate>
                <TitleContainer>
                    <Title>Recent Updates</Title>
                    <ViewAll state={state.root} href={`/models`} className="action">
                        View all
                    </ViewAll>
                </TitleContainer>
                <Cards>
                    {recentModels.map(model => (
                        <Navigate
                            state={state.root}
                            key={model.modelId}
                            href={`/models/${model.modelId}`}
                        >
                            <CardImageTitled
                                title={model.name}
                                image={model.image.portraight}
                                blurTitle={model.loading}
                            />
                        </Navigate>
                    ))}
                </Cards>
                <>
                    {features.map(feature => (
                        <React.Fragment key={feature.title}>
                            <Title>{feature.title}</Title>
                            <Cards>
                                {feature.content.map(item => {
                                    return (
                                        <Navigate
                                            state={state.root}
                                            key={item.videoId || item.modelId}
                                            href={
                                                (item.videoId && `/videos/${item.videoId}`) ||
                                                `/models/${item.modelId}`
                                            }
                                            disabled={item.loading}
                                        >
                                            <CardImageTitled
                                                title={item.title}
                                                titleSize="0.875em"
                                                textTransform="none"
                                                image={item.image.landscape}
                                                blurTitle={item.loading}
                                            />
                                        </Navigate>
                                    );
                                })}
                            </Cards>
                        </React.Fragment>
                    ))}
                </>
                {next && (
                    <SlideInfo
                        size={constant.size}
                        info={next.description.coming}
                        title={next.models[0].name}
                        type="Coming soon"
                        source={next.homeImage}
                        style={linkStyle}
                    />
                )}
                {!user && (
                    <OurStory>
                        <h1>{ourStory.name}</h1>
                        <TextContent
                            dangerouslySetInnerHTML={{ __html: ourStory.value }}
                        ></TextContent>
                    </OurStory>
                )}
                {user && (
                    <News>
                        <Column size="66.6%">
                            <Title>{news.name}</Title>
                            <TextContent
                                dangerouslySetInnerHTML={{ __html: news.value }}
                            ></TextContent>
                        </Column>
                        <Column>
                            <Title>DISCOUNTS FROM OUR FRIENDS</Title>
                            <Discounts />
                        </Column>
                    </News>
                )}
            </StyledPage>
        </>
    );
}
